import {Link, TokenManager} from '@brikit/tabjay-ui-kit'
import _ from 'lodash'
import {createAxiosInstance} from 'utils/request_interceptors'
import {URL} from 'utils/urls'

// NOTE: This is where we make client-side requests to Google APIs.

const googleDriveApi = createAxiosInstance(URL.api.google.drive)

const requestHeader = (token) => {
  return {headers: {'Authorization': `Bearer ${token.accessToken}`}}
}

const GoogleApi = {

  getFiles: async function (links) {
    const promises = links.map(r =>
      this.getFile(r.externalId).then((res) => {
        return {
          id: r.id,
          externalId: res?.id,
          thumbnailLink: res?.thumbnailLink,
          mimeType: res?.mimeType,
          title: res?.title,
          token: res?.token
        }
      })
    )
    const files = await Promise.all(promises)
    const successfulFiles = files.filter((f) => f !== undefined && f.thumbnailLink !== undefined)
    return successfulFiles
  },

  getFile: async function (externalId, linksPanel = false) {
    const tokens = await TokenManager.googleTokens()
    if (_.isEmpty(tokens)) {return }
    let tokensLimit = tokens.slice(0, 3) //limit tokens for now

    for (const token of tokensLimit) {
      try {
        const fields = 'id,title,thumbnailLink,mimeType'
        const res = await googleDriveApi.get(`${externalId}?fields=${fields}`, requestHeader(token))

        // Modify thumbnailLink to get larger image size
        const resizedThumbnailUrl = res.data.thumbnailLink.replace("s220", "s640")
        res.data.thumbnailLink = resizedThumbnailUrl

        const linksPanelFormat = {
          data: [{
            name: res.data.title,
            image: res.data.thumbnailLink,
            description: '',
            icon: ''
          }],
          token: token
        }

        const data = linksPanel ? linksPanelFormat : {...res.data, token: token}
        return (res.status === 200) ? data : console.log('Unhandled response: ', res)
      }
      catch (err) {
        // console.log('Google file error: ', err)
      }
    }
  },


  handleErrors: function (err, onError) {
    let error = err.response
    if (error) {onError(error)}
  },

  updateThumbnailCache: async function (googleLink, queryClient) {
    const thumbnails = await GoogleApi.getFiles(googleLink)

    thumbnails.forEach(t => {
      // Update thumbnail cache with new thumbnail
      queryClient.setQueryData('/google-thumbnails', oldData => [...oldData, t])

      // Update new link with thumbnail
      const matchingLink = Link.findById(t.id)
      matchingLink.image = t.thumbnailLink
    })
  },

}

export default GoogleApi
