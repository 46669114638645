import {getSetting} from '@brikit/tabjay-ui-kit'
import axios from 'axios'

const tabjayNodeApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NODE_API,
  headers: {'Accept': 'application/json'},
})

// Add this interceptor which will run on each api request
tabjayNodeApi.interceptors.request.use(function (config) {
  // const currentUserId = getSetting('tabjay.current.user.id')

  // Add currentUserId to the request data if it's not already there
  // if (config.method === 'get') {
  //   if (!config.params?.currentUserId) {
  //     config.params = {...config.params, currentUserId}
  //   }
  // } else {
  //   if (!config.data?.currentUserId) {
  //     config.data = {...config.data, currentUserId}
  //   }
  // }

  return config
})

tabjayNodeApi.interceptors.response.use((response) => {
  return response
}, async function (error) {
  return Promise.reject(error)
})

export {
  tabjayNodeApi
}

