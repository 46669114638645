const setCookie = (name, value, maxAgeInSeconds = 60 * 60 * 24 * 100) => {
  // NOTE: default maxAgeInSeconds is 100 days
  return document.cookie = `${name}=${value}; path=/; Max-Age=${maxAgeInSeconds}; SameSite=Lax`
}

const removeCookie = (name) => {
  return document.cookie = `${name}=; path=/; expires=${new Date(0).toUTCString()}`
}

export {removeCookie, setCookie}

