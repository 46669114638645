import {TokenManager} from '@brikit/tabjay-ui-kit'
import axios from 'axios'
import {tabjayNodeApi} from 'utils/tabjay_api_utils'
import {URL} from './urls'

// NOTE: These interceptors are used to refresh expired tokens 
// for making requests to Google and Atlassian APIs in the client.

const createAxiosInstance = (baseURL) => {
  const newInstance = axios.create({baseURL})

  // all provider api requests
  newInstance.interceptors.request.use((request) => {
    if (isAtlassianApiRequest(request.baseURL)) {configAtlassianHeaders(request)}
    return request
  })
  // all provider api responses
  newInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    if (error && isTokenRefreshRequired(error)) {return refreshToken(error)}
    return Promise.reject(error)
  })
  return newInstance
}

const isTokenRefreshRequired = (error) => {
  const originalRequest = error.config
  const data = error.response?.data
  const status = error.response?.status
  let isUnauthorized = false

  if (status === 401) {
    isUnauthorized = true
  }
  if (status === 403 && data.error.message === "Daily Limit for Unauthenticated Use Exceeded. Continued use requires signup.") {
    isUnauthorized = true
  }
  if (status === 403 && data.message === "Current user not permitted to use Confluence") {
    isUnauthorized = true
  }
  return !originalRequest._retry && isUnauthorized
}

const refreshToken = (error) => {
  const originalRequest = error.config
  originalRequest._retry = true //tell axios to retry failed request
  const originalRequestToken = originalRequest.headers.Authorization.split(' ')[1]
  const expiredToken = TokenManager.getTokenByAccessToken(originalRequestToken)

  if (expiredToken != null) {
    return getNewAccessToken(expiredToken)
      .then(res => {
        if (res.status === 200) {
          const newToken = {
            email: expiredToken.email,
            provider: expiredToken.provider,
            accessToken: res.data.access_token,
            expiresIn: res.data.expires_in
          }
          TokenManager.updateAccessToken(newToken)
          originalRequest.headers.Authorization = `Bearer ${newToken.accessToken}`
          return axios(originalRequest)
        }
      }).catch(err => {
        return err
      })
  }
  return Promise.reject(error)
}

const isAtlassianApiRequest = (url) => url.includes('api.atlassian.com')

const getNewAccessToken = (expiredToken) => {
  const data = {email: expiredToken.email, provider: expiredToken.provider}
  return tabjayNodeApi.post(URL.route.refresh_token, data)
}

const configAtlassianHeaders = (config) => {
  let tokens = TokenManager.atlassianTokens()
  let token = tokens && tokens.length > 0 ? tokens[0] : null

  if (token) {
    config.headers.Authorization = `Bearer ${token.accessToken}`
  }
}

export {
  createAxiosInstance,
  getNewAccessToken
}

