import {ifBrowserEnv} from '@brikit/tabjay-ui-kit'
import {setCookie} from 'utils/cookies'

const authorizeAtlassian = async (formData) => {
  saveFormInLocalStorage(formData)
  await setRedirectUrl(formData)
  //TODO: use lucia auth
}

const authorizeGoogle = async (formData) => {
  saveFormInLocalStorage(formData)
  await setRedirectUrl(formData)
  //TODO: use lucia auth
}

const setRedirectUrl = async (formData) => {
  const currentPath = ifBrowserEnv(() => {return window.location.pathname})
  const openPanelParams = formData?.authorizedByPanel ? `?authorizedByPanel=true` : ''
  const redirectUrl = currentPath + openPanelParams

  await setCookie('authorize-redirectUrl', redirectUrl, 30)
}

const saveFormInLocalStorage = (formData) => {
  if (!formData) return
  const formDataInObjectFormat = convertModelsIntoRawData(formData)
  localStorage.setItem('addLinksPanelData', JSON.stringify(formDataInObjectFormat))
}

const clearPreviousFormData = () => {
  const currentUrl = window.location.href
  const newUrl = currentUrl.replace('?authorizedByPanel=true', '')
  window.history.replaceState(null, "", newUrl)
  localStorage.removeItem('addLinksPanelData')
}

const authorizePrompts = {
  google: authorizeGoogle,
  atlassian: authorizeAtlassian
}

const convertModelsIntoRawData = (formData) => {
  const data = (link) => ({
    id: link.id,
    key: link.key,
    manual: link.manual,
    url: link.url,
    name: link.name,
    description: link.description,
    image: link.thumbnailUrl,
    iconUrl: link.iconUrl,
    selected: link.selected,
  })

  const links = formData?.links?.map((link) => data(link))
  return {...formData, links}
}

export {authorizePrompts, clearPreviousFormData}
