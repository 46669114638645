import {isBrowserEnv, TabjayCookies} from '@brikit/tabjay-ui-kit'
import _ from 'lodash'

// If shouldAdd, add className to element. If not shouldAdd, remove className from element
const adjustClass = (reactRef, className, shouldAdd) => reactRef && (shouldAdd ? reactRef.current.classList.add(className) : reactRef.current.classList.remove(className))

// If explicit is provided, return it. Otherwise, return seedString converted to a slug appropriate for a className
const classNameFrom = (explicit, seedString) => explicit ? explicit : slugFromName(seedString)

// Useful for stopping event handlers from responding
const doNothing = (e) => {
  e && e.preventDefault()
  return false
}

// map([{key: 1, other: 'a'}, {key: 2, other: 'b'}], d => d.key)  => {1: {key: 1, other: 'a'}, 2: {key: 2, other: 'b'}}
const map = (data, f) => {
  const m = {}
  data.forEach((i) => m[f(i)] = i)
  return m
}

// Return an opaque rgb() equivalent of hexCode with opacity drawn on a white backgroundColor
// '#a3fe49', .7 => 'rgb(170, 210, 79)'
// const opaqueRGBColorDrawnWithOpacityOnWhiteBackground = (color, opacity) => {
//     const rgb = d3.rgb(color)
//
//     const r = rgb.r * opacity
//     const g = rgb.g * opacity
//     const b = rgb.b * opacity
//
//     const bg = 255 * (1 - opacity)
//
//     return `rgba(${r + bg},${g + bg},${b + bg})`;
// }

// 'hello everyone how-are-you' => 'HelloEveryoneHowAreYou'
const pascalCase = (someString) => _.startCase(_.camelCase(someString)).replace(/ /g, '')

// 'hello everyone how are you' => 'Hello everyone how are you'
const sentenceCase = (someString) => _.capitalize(someString)

// '5. Hello Everyone' => 'hello-everyone'
const slugFromName = (someString) => {
  return _.kebabCase(stripLeadingNumberFromName(someString))
}

// 'hello everyone how-are-you' => 'HelloEveryoneHowAreYou'
const startCase = (someString) => _.startCase(someString)

// '5. Hello Everyone' => 'Hello Everyone'
const stripLeadingNumberFromName = (someString) => {
  const match = someString && someString.match(/[0-9]+\.\s*(.*)/)
  return match ? match[1] : someString
}

const toBoolean = (someString) => {
  if (_.isBoolean(someString)) {return someString}

  let value = null
  switch (someString) {
    case 'true': value = true; break
    case 'false': value = false; break
    case 'null': value = null; break
    case null: value = null; break
    default: value = undefined
  }
  return value
}

const toInteger = (someString) => {
  return _.toInteger(someString)
}

const isLinkSupported = (sourceType) => {
  return ['google_doc', 'confluence_page', 'confluence_blog_post'].includes(sourceType)
}

const isConfluenceUrl = (url) => url.includes('atlassian.net/wiki')

const isGoogleUrl = (url) => url.includes('docs.google.com') || url.includes('drive.google.com')

const isUrlFromExternalLink = (url) => isGoogleUrl(url) || isConfluenceUrl(url)

const getIdFromGoogleUrl = (url) => {
  const match = url.match(/[a-zA-Z0-9\-_]{15,50}/)
  return match ? match[0] : null
}

const getIdFromConfluenceUrl = (url) => {
  if (isConfluenceUrl(url)) {
    let splitUrl = url.split('/')
    if (splitUrl[splitUrl.length - 2] === 'pages') {
      return splitUrl[splitUrl.length - 1]
    }
    else {
      return splitUrl[splitUrl.length - 2]
    }
  }
}

const isGoogleType = (link) => ['google_doc', 'google_sheet', 'google_slide', 'google_form', 'google_file'].includes(link.source_type)

const isConfluenceType = (link) => ['confluence_page', 'confluence_blog_post'].includes(link.source_type)

const isWebPageType = (link) => link.source_type === 'web_page'

const isGoogleDocsSupportedUrl = (url) => {
  let supportedSubstrings = ['docs.google.com/document']
  return supportedSubstrings.some(supportedSubstring => url.includes(supportedSubstring))
}

const isUrlDeeplySupported = (url) => {
  return (isConfluenceUrl(url) || isGoogleDocsSupportedUrl(url))
}

const findLink = (plan, link_id) => {
  return plan.links.find(link => link.id === link_id)
}

const findLinkByExternalId = (plan, link_external_id) => {
  return plan.links.find(link => link.external_id === link_external_id)
}

const findLinksByExternalId = (plan, link_external_id) => {
  return plan.links.filter(link => (link.external_id === link_external_id))
}

const searchLinksInClient = (plan, value) => {
  if (plan.links.length <= 0) {return []}

  const searchQuery = (link) => containsText(link.name, value) || containsText(link.source_type, value) || (link.description && containsText(link.description, value))
  return plan.links.filter(link => (searchQuery(link)))
}

// TODO: remove this
const findTask = (plan, task_id) => {
  let task = plan.tasks.find(task => task.id === task_id)
  return task
}

const containsText = (string = '', substring = '') => {
  return string.toLowerCase().includes(substring.toLowerCase())
}

const spaceToDash = (str) => {
  return str.split(' ').join('-')
}

const updateLocalStorage = (key, value) => {
  if (!isBrowserEnv) return null
  value == null ? localStorage.removeItem(key) : localStorage.setItem(key, value)
}

const getInternalLinkLink = (url, plan) => {
  let externalId = null
  let internalLink = null

  if (isGoogleUrl(url)) {externalId = getIdFromGoogleUrl(url)}
  if (isConfluenceUrl(url)) {externalId = getIdFromConfluenceUrl(url)}

  if (externalId !== null) {
    let foundLink = findLinkByExternalId(plan, externalId)
    let foundTask = null

    if (foundLink) {
      foundTask = findTask(plan, foundLink.task_id)
    }
    if (foundLink && foundTask) {
      internalLink = {
        'link': `/link/${foundLink.id}/${plan.name}/${foundTask.name}/${foundLink.name}`.replace(/\s/g, "-"),
        'text': foundLink.name
      }
    }
  }
  return internalLink
}

const parseEmailDomain = (email) => {
  return email.split('@')[1]
}

const jsTimeNowToRailsFormat = () => {
  // a function to convert js time to match rails db created_at timestamps
  // x.created_at.to_i will give an 10 digit time value
  // js Date.now() will give a 13 digit time value
  // this function will convert the js time to 10 digits
  const timeStr = Date.now().toString()
  return timeStr.slice(0, timeStr.length - 3)
}

const timeInSeconds = (time) => Math.floor(time / 1000)

const occuredThisWeek = (time) => {
  const milisecondsElapsed = parseInt(time) - Date.now()
  return milisecondsElapsed < 604800000
}

const paginateArray = (arr, limit = 8) => {
  return arr.length > limit ? _.dropRight(arr, arr.length - limit) : arr
}

const sortAssetByMostOccurences = (plan, assetType, assetProperty) => {
  const assetList = plan[assetType]
  const assetPropertyCount = _.countBy(assetList, assetProperty)
  const assetListWithCount = assetList.map(asset => ({...asset, count: assetPropertyCount[asset.name]}))
  const uniqList = _.uniqBy(assetListWithCount, assetProperty)
  return _.orderBy(uniqList, ['count'], ['desc'])
}

const taskName = (task) => `${task.project} ${task.activity}`

// TODO: convert mapsInSameOrder to findByIds
const mapInSameOrder = (unsortedArray, referenceArray) => {
  return referenceArray.map((x) => unsortedArray.find((y) => y.id === x.id))
}

const intersectionOrderedByIds = (ids = [], items) => {
  //NOTE: _.intersectionBy has both order and data returned from the first array.
  // This function is similiar to _.intersectionBy except it uses the first array
  // only for ordering and returns the data from the second array in order.
  if (ids.length <= 0) return []
  return ids.map((id) => items.find((item) => item.id === id))
}

export const findAssetsInPlan = (ids, plan, assetType) => {
  if (!plan || !assetType) {return null}
  return intersectionOrderedByIds(ids, plan[assetType])
}

const userFullName = (user) => {
  if (!user) {return null}
  return user.first_name
    ? `${user.first_name} ${user.last_name || ''}`
    : null
}

export const isSameWord = (str1, str2) => {
  return _.toLower(str1) === _.toLower(str2)
}

export const isSubstringInWord = (str, substring) => {
  return _.toLower(str).includes(_.toLower(substring))
}

export const isValueInArray = (array, value) => {
  return array.some(e => _.isEqual(e, value))
}

export const isValueInArrayBy = (array, key, value) => {
  return array.some(e => _.isEqual(e[key], value))
}

export const signOutHelper = () => {
  localStorage.removeItem('addLinksPanelData')
  TabjayCookies.removeCookie('browser-extension-id')
  // The tabjay session cookie is removed in the logout api route
}

export const isExtension = process.env.NEXT_PUBLIC_IS_EXTENSION === 'true'

export {
  adjustClass,
  classNameFrom,
  doNothing, findLink,
  findLinkByExternalId,
  findLinksByExternalId,
  findTask, getIdFromConfluenceUrl, getIdFromGoogleUrl, getInternalLinkLink, isConfluenceType, isConfluenceUrl, isGoogleType, isGoogleUrl, isLinkSupported, isUrlDeeplySupported, isUrlFromExternalLink, isWebPageType, jsTimeNowToRailsFormat, map, mapInSameOrder, occuredThisWeek,
  paginateArray, parseEmailDomain, pascalCase, searchLinksInClient, sentenceCase,
  slugFromName, sortAssetByMostOccurences, spaceToDash, startCase,
  stripLeadingNumberFromName, taskName, timeInSeconds, toBoolean,
  toInteger, updateLocalStorage, userFullName
}

